
import { defineComponent, ref, reactive } from 'vue'
import SbomDataService from '@/services/SbomDataService'
import ResponseData from '@/types/ResponseData'
import { ElMessage } from 'element-plus'
import { mapMutations } from 'vuex'

export default defineComponent({
  name: 'SbomVulnerabilityTable',
  components: {},
  props: {},
  data() {
    return {}
  },
  computed: {}, 
  watch: {},
  methods: {
    ...mapMutations(['setUser']),
    handleLogin() {
      SbomDataService.handleLogin()
        .then((response: ResponseData) => {
          const { authUrl } = response.data
          location.replace(authUrl)
        })
        .catch((e: any) => {
          if (e.response && e.response.status == 500) {
            ElMessage({
              message: e.response.data,
              type: 'error',
            })
          }
        })
    },

    init() {
      if (this.$route.path === '/login/hasinfo') {
        SbomDataService.getUserInfo().then(async (res: ResponseData) => {
          const { data } = res.data
          this.setUser({
            name: data.name,
            userId: data.id,
            userLogin: data.login,
            avatarUrl: data.avatarUrl
          })
          this.$router.push('/')
        })
      }
    },
  },
  mounted() {
    this.init()
  },
})
